<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--header">
        <h1>Groups</h1>
        <span>
          <button class="btn mr-3 btn__small" v-bind:class="{ 'btn__dark': showAllGroups, 'btn__outlined': !showAllGroups }"  @click="showAll()">All Groups</button>
          <button class="btn mr-3 btn__small" v-bind:class="{ 'btn__dark': showMyGroups, 'btn__outlined': !showMyGroups }" @click="showMine()">My Groups</button>
          <!-- <button class="btn mr-3" v-bind:class="{ 'btn__dark': showFollower, 'btn__outlined': !showFollower }" @click="showFollowerGroups()">Follower Groups</button> -->
          <router-link :to="{name: 'addgroup'}" class="color--text">
            <button class="btn btn__small btn__outlined">New Group</button>
          </router-link>
        </span>
        
      </div>
      <div class="dashboard__container--body pt-0">
          <Loader v-if="!groups || groups.length == 0" />
          <vue-good-table
            v-if="showAllGroups && groups || groups.length >= 1"
              :columns="columns"
              :rows="groups"
               styleClass="vgt-table condensed"
              :search-options="{
                enabled: true,
                placeholder: 'Search this table',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 20,
              }"
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'created'">
                <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
              </span>
              <span v-else-if="props.column.field == 'owner'">
                <span v-if="props.row.owner">
                  {{props.row.owner.firstName}}
                </span>
                <span v-if="props.row.owner">
                  {{props.row.owner.lastName}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'title'">
                <router-link :to="`/groups/` + props.row.id + `/edit`">
                  {{props.row.title}}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'watchlist'">
                <button v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star"></i></button>
                 <button v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
              </span>
              <span v-else-if="props.column.field == 'visible'">
                <span v-if="props.row.visible"><i class="fa-regular fa-check"></i></span>
              </span>
              <span v-else-if="props.column.field == 'applicants'">
                <span v-if="props.row.applicants" style="color:green;">
                  {{props.row.applicants.length}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'members'">
                <span v-if="props.row.members">
                  {{props.row.members.length}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'admins'">
                <span v-if="props.row.admins && props.row.admins.length > 0">
                  <button class="btn btn__small chip mr-2" v-for="(item, index) in props.row.admins" :key="item.id">{{item.name}}</button>
                </span>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>

          <vue-good-table
            v-if="showMyGroups"
              :columns="columns"
              :rows="myGroups"
               styleClass="vgt-table condensed"
              :search-options="{
                enabled: true,
                placeholder: 'Search this table',
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 20,
              }"
            >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'created'">
                <span v-if="props.row.created">{{formatDate(props.row.created)}}</span>
              </span>
              <span v-else-if="props.column.field == 'watchlist'">
                <button v-if="!isWatchList(props.row)" class="btn btn__icon" @click="addWatch(props.row)"><i class="fa-regular fa-star"></i></button>
                 <button v-if="isWatchList(props.row)" class="btn btn__icon" @click="removeWatch(props.row)"><i class="fa-solid fa-star warning"></i></button>
              </span>
              <span v-else-if="props.column.field == 'owner'">
                <span v-if="props.row.owner">
                  {{props.row.owner.firstName}}
                </span>
                <span v-if="props.row.owner">
                  {{props.row.owner.lastName}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'title'">
                <router-link :to="`/groups/` + props.row.id + `/edit`">
                  {{props.row.title}}
                </router-link>
              </span>
              <span v-else-if="props.column.field == 'applicants'">
                <span v-if="props.row.applicants" style="color:green;">
                  {{props.row.applicants.length}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'members'">
                <span v-if="props.row.members">
                  {{props.row.members.length}}
                </span>
              </span>
              <span v-else-if="props.column.field == 'admins'">
                <span v-if="props.row.admins && props.row.admins.length > 0">
                  <button class="btn btn__small chip mr-2" v-for="(item, index) in props.row.admins" :key="item.id">{{item.name}}</button>
                </span>
              </span>
              <span v-else>
                {{props.formattedRow[props.column.field]}}
              </span>
            </template>
          </vue-good-table>

         <!--  <vue-good-table
          v-if="showFollower"
            :columns="columns2"
            :rows="venues"
            styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
            }"
            @on-row-click="onRowClick2"
          >
        </vue-good-table> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'groups',
  data: () => ({
    performingRequest: false,
    showAllGroups: true,
    showMyGroups: false,
    showFollower: false,
    columns: [
      {
        label: 'Visible',
        field: 'visible',
        tdClass: 'text-center',
      },
      {
        label: 'Watchlist',
        field: 'watchlist',
        tdClass: 'text-center-all',
        width: '80px'
      },
      {
        label: 'Title',
        field: 'title',
      },
      {
        label: 'Location',
        field: 'location',
      },
      {
        label: 'Admins',
        field: 'admins',
      },
      {
        label: 'Members',
        field: 'members',
      },
      {
        label: 'New Applicants',
        field: 'applicants',
      },
    ],
    columns2: [
      {
        label: 'Name',
        field: 'title',
      },
      {
        label: 'Followers',
        field: 'followers',
      },
      {
        label: 'City',
        field: 'address.city',
        thClass: 'hidden-small',
        tdClass: 'hidden-small',
      },
      {
        label: 'State',
        field: 'address.state',
      },
    ]
  }),
  created () {
    this.$store.dispatch("getGroups")
  },
  computed: {
    ...mapState(['groups', 'hiddenGroups', 'currentUser', 'userProfile']),
    myGroups: function() {
      return this.groups.filter(group => {
        return (group.owner == this.currentUser.uid || (group.admins && group.admins.includes(this.currentUser.uid)))
      })
    },
  },
  components: {
    Loader,
  },
  methods: {
    isWatchList(row) {
      if (this.userProfile && this.userProfile.groupWatchList && this.userProfile.groupWatchList.includes(row.id)) {
        return true
      } else {
        return false
      }
    },
    async addWatch(row) {
      await this.$store.dispatch('watchGroup', row.id)
    },
    async removeWatch(row) {
      await this.$store.dispatch('unWatchGroup', row.id)
    },
    showFollowerGroups() {
      this.showFollower = true
      this.showAllGroups = false
      this.showMyGroups = false
    },
    showMine() {
      this.showFollower = false
      this.showAllGroups = false
      this.showMyGroups = true
    },
    showAll() {
      this.showFollower = false
      this.showAllGroups = true
      this.showMyGroups = false
    },
    // onRowClick(params) {
    //   let url = `/groups/` + params.row.id + `/edit`
    //   console.log(url)
    //   router.push(url)
    // },
    // onRowClick2(params) {
    //   let url = `/followersgroups/` + params.row.id
    //   console.log(url)
    //   router.push(url)
    // },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMMM Do YYYY, hh:mm a')
      } else {
        return null
      }
    },
  },
  beforeDestroy () {
    this.performingRequest = null
    delete this.performingRequest
    this.showAllGroups = null
    delete this.showAllGroups
    this.showMyGroups = null
    delete this.showMyGroups
    this.showFollower = null
    delete this.showFollower
    this.columns = null
    delete this.columns
    this.$store.dispatch('clearGroups')
  }
}
</script>